/* eslint-disable react/no-danger */
import React, { useState, useEffect, useContext } from 'react';
import Helmet from 'react-helmet';
import { FeaturesSlider } from '@latitude/features-slider';
import { getUrlFromEnv } from '@/utils/getUrlFromEnvUtil';
import Layout from '@/components/layout';
import StickyNavigationBranded from '@/components/StickyNavigation/StickyNavigationBranded';
import Faq from '@/components/Faq/Faq';
import PLData from '@/data/pages/personal-loan.json';
import { redirectUrl } from '@/components/EstimateRateWidget/utils';
import CalcData from '../data/pages/loans/loan-repayment-calculator.json';
import { ImportantInformation } from '@latitude/important-information';
import {
  BREAKPOINT,
  SOFT_QUOTE_LOANS_AU_URL,
  PL_APPLY_CTA_TRACK_ID
} from '@/utils/constants';
import { graphql } from 'gatsby';
import footerData from '@/data/json/footer.json';
import PageData from '@/data/pages/car-loan.json';
import heroImage from '../images/hero/car-loans-image.webp';
import styled from 'styled-components';
import Button from '../components/Button/Button';
import SvgInline from '@latitude/svg-inline';
import PageHeaderSection from '@/components/PageLayout/PageHeader';
import { PageContext } from '@/context/PageContext';
import HeroBanner from '@/components/lab-components/HeroBanner';
import Section from '@latitude/section';
import PersonalLoanCalculator from '@/components/PersonalLoanCalculator2/PersonalLoanCalculator';
import ProductComparisonSection from '@/components/PersonalLoanPageContent/ProductComparisonSection';
import CardCollection from '@/components/lab-components/CardCollection';
import ProofPointCollection from '@/components/lab-components/ProofPointCollection';
import { AnalyticsContext } from 'latitude-analytics';

const HomeButton = styled(Button)`
  && {
    background-color: #21a637;
    color: #fff !important;
    white-space: nowrap;
    font-size: 22px;
  }

  @media (max-width: ${BREAKPOINT.MD}) {
    && {
      font-size: 15px;
      width: 100%;
      white-space: break-spaces;
    }
  }

  margin-bottom: 8px;
  &:hover {
    box-shadow: 0 1px 13px 0 rgba(0, 0, 0, 0.4);
    transition: all 150ms ease-in-out;
  }
`;
const CTA_BUTTON_LABEL = 'Start here';
const COLOUR_PALE_BLUE = '#E5F6FF';

const CarLoanPage = props => {
  const applyUrl = `${getUrlFromEnv(
    'funnel-start-loans'
  )}${SOFT_QUOTE_LOANS_AU_URL}`;

  // soft quote
  const [softQuoteHref, setSoftQuoteHref] = useState();

  /** Contentful Data */
  const contentfulPageData = useContext(PageContext);
  const state = contentfulPageData?.updatedBody || {};

  const featureSliderData = [
    {
      heading: 'Why choose a Latitude loan?',
      featureCards: PLData.content.whyChooseLatitude
    }
  ];
  /** - END - */

  const [analytics] = useContext(AnalyticsContext);

  // adding pageData to analytics
  analytics.pageData = {
    page_pageTitle: 'car-loan',
    page_pageType: 'product-details-page',
    page_siteSection: 'loans',
    page_siteSubsection: 'car-loan',
    product_productid: ['PLAULF-WEB']
  };

  useEffect(() => {
    sessionStorage.setItem('purpose', 'purpose'); // reset session val used in `EstimateRateWidgetSection`
    setSoftQuoteHref(redirectUrl('au', true));
  }, []);

  function transformData(data) {
    return {
      title: {
        nodeType: 'document',
        data: {},
        content: [
          {
            nodeType: 'heading-3',
            data: {},
            content: [
              {
                nodeType: 'text',
                value: 'Recommended reads',
                marks: [],
                data: {}
              }
            ]
          },
          {
            nodeType: 'paragraph',
            data: {},
            content: [
              {
                nodeType: 'text',
                value: '',
                marks: [],
                data: {}
              }
            ]
          }
        ]
      },
      cards: data?.map(item => ({
        image: {
          file: {
            url: `/life-done-better/images/${item?.node?.frontmatter?.thumbnail}`
          }
        },
        title: {
          data: {},
          content: [
            {
              data: {},
              content: [
                {
                  data: {},
                  marks: [],
                  value: item?.node?.frontmatter?.title,
                  nodeType: 'text'
                }
              ],
              nodeType: 'paragraph'
            }
          ],
          nodeType: 'document'
        },
        tileUrl: item?.node?.frontmatter?.path
      }))
    };
  }

  return (
    <Layout
      location={props.location}
      title={PageData.title}
      customFooter={footerData}
    >
      <main
        className="navigation-spacer navigation-spacer--hasStickyNav"
        css="background-color:#f8f8f8;"
      >
        <Helmet>
          <link
            rel="canonical"
            href={`https://www.latitudefinancial.com.au${PageData.path}`}
          />
          <title>{PageData.metaTitle}</title>
          <meta name="description" content={PageData.metaDesc} />
        </Helmet>

        {state?.heroBannerData?.[0] ? (
          <HeroBanner {...state?.heroBannerData[0]} />
        ) : (
          <PageHeaderSection
            title={<>Car Loans</>}
            subTitle={
              <>
                Need new wheels?
                <br />
                Take control of your finances. Apply online and get a response
                in 60 seconds.
              </>
            }
            pageImage={heroImage}
            pageImageAlt="Car loan"
          >
            <div className="text-white row">
              <div className="text-left col-6 col-md-5">
                <HomeButton
                  href={applyUrl}
                  trackId="personal-loans-button"
                  trackEventData={{
                    label: CTA_BUTTON_LABEL,
                    location: 'Hero',
                    category: 'button-link'
                  }}
                  trackProductId={['PLAULF-WEB']}
                  className="button--primary"
                >
                  {CTA_BUTTON_LABEL}
                </HomeButton>
              </div>
              <div className="p-0 text-center col-1">
                <SvgInline name="stopwatch" />
              </div>
              <div className="pt-1 text-left col-5 col-md-6 padding-right HeroContent__insettext">
                Find out your estimated interest rate in 2 minutes
              </div>
            </div>
          </PageHeaderSection>
        )}

        <div className="d-none d-lg-block" css="position:relative; z-index:10;">
          <StickyNavigationBranded
            items={PLData.nav}
            phoneNumber={PLData.content.phoneNumber}
            ctaHref={applyUrl}
            ctaText={CTA_BUTTON_LABEL}
            offsetElem="[data-sticky-navigation-offset]"
            trackEventData={{
              category: 'cta',
              action: 'quote-link',
              location: 'sticky'
            }}
            trackId="sticky-get-started"
            {...state?.inPageNavData?.[0]}
          />
        </div>
        <FeaturesSlider
          key={(state?.featureSliderData?.[0] || featureSliderData[0]).heading}
          id="why-us"
          data={
            (state?.featureSliderData?.[0] || featureSliderData[0]).featureCards
          }
          className="pt-4 why-choose pt-md-5"
          heading={
            (state?.featureSliderData?.[0] || featureSliderData[0]).heading
          }
          subheading={
            (state?.featureSliderData?.[0] || featureSliderData[0]).description
          }
          css={`
            background-color: #e6e6e6;
            && ul {
              text-align: left;
              @media (max-width: ${BREAKPOINT.MD}) {
                padding: 2px 20px 20px 20px;
                text-align: center;
                list-style-position: inside;
              }
            }
            && h4 {
              text-align: left;
              font-size: 32px;
              line-height: 36px;
              margin: 0px;
              @media (max-width: ${BREAKPOINT.MD}) {
                text-align: center;
              }
            }
            &&.lfs-features-slider {
              padding: 32px 0px;
            }
            && h4 > p {
              padding: 0px;
              margin: 0px;
            }
            && div.lfs-card-icon {
              padding: 0;
              width: 120px !important;
              height: 120px !important;
              @media (max-width: ${BREAKPOINT.MD}) {
                margin-bottom: 0px;
              }
            }
            && svg {
              border-radius: 50%;
              background-color: white;
              padding: 0px 23px;
            }
            && .lfs-card-text {
              @media (max-width: ${BREAKPOINT.MD}) {
                margin-top: 0px;
              }
            }
            && .lfs-card {
              @media (max-width: ${BREAKPOINT.MD}) {
                gap: 16px;
              }
            }
          `}
        />

        <Section
          id="repayment-calculator"
          heading="Get an estimate of your repayments"
          css={`
            background-color: ${COLOUR_PALE_BLUE};
            padding-top: 32px;
            padding-bottom: 32px;
            && h2.text-center {
              text-align: left !important;
              font-size: 32px;
              line-height: 36px;
              margin-top: 0px;
              margin-bottom: 30px;
            }
          `}
        >
          <PersonalLoanCalculator
            loans1
            ctaButtonLabel="Get my rate estimate"
            applyCTATrackId={PL_APPLY_CTA_TRACK_ID}
          />
        </Section>
        <ProductComparisonSection
          id="compare-products"
          heading="Compare our fixed and variable loan options to find the right fit"
        />
        <ProofPointCollection
          id="calculators-and-tools"
          data={
            state?.proofPointCollectionData?.[0] ||
            CalcData?.contentful?.proofPointCollectionData?.[0]
          }
        />
        <CardCollection
          id="applying-is-simple"
          data={
            state?.cardCollectionData?.[0] ||
            PLData?.contentful?.applyingIsSimple?.[0]
          }
        />

        <CardCollection
          id="Recommended reads"
          data={
            state?.cardCollectionData?.[1] ||
            transformData(pageQuery?.allMarkdownRemark?.edges)
          }
          category="text-link"
        />
        <Faq data={PageData.content.faq} {...state?.faqData?.[0]} />
        <ImportantInformation
          data={require('@/data/json/disclaimer/personal-loan1.json')}
          sectionOneColummClasses="col-10 offset-1 col-lg-5 offset-lg-1"
          sectionTwoColummClasses="col-10 offset-1 col-lg-5 offset-lg-0"
          additionalPaymentsDisclaimerOne
          {...state?.importantInfoData?.[0]}
          css={`
            .important-info svg {
              stroke-width: unset;
            }
            .important-info__header > h3 {
              @media (min-width: 992px) {
                margin: 0 0 0 6px;
              }
            }
          `}
        />
        <script
          type="application/ld+json"
          dangerouslySetInnerHTML={{
            __html: JSON.stringify({
              '@context': 'http://schema.org',
              '@type': 'LoanOrCredit',
              name: PLData.content.loanDetails.personal.name,
              loanTerm: PLData.content.loanDetails.personal.loanTerm,
              annualPercentageRate:
                PLData.content.loanDetails.personal.annualPercentageRate,
              amount: PLData.content.loanDetails.personal.amount
            })
          }}
        />
        {/* </Box> */}
      </main>
    </Layout>
  );
};

export const pageQuery = graphql`
  {
    allMarkdownRemark(
      filter: {
        fileAbsolutePath: {
          glob: "**/src/templates/life-done-better/articles/*.md"
        }
        frontmatter: {
          title: {
            in: [
              "How to Find the Best New Car Loan"
              "Our Pick of Australia's Best Value Used Cars"
              "How To Choose The Right Car Insurance For You"
            ]
          }
        }
      }
    ) {
      edges {
        node {
          html
          frontmatter {
            title
            path
            thumbnail
          }
        }
      }
    }
  }
`;
export default CarLoanPage;
